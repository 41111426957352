<template>
  <div
    class="relative h-full"
    :class="{
      'overflow-auto max-h-[100vh]': !signatureModal.open,
      'overflow-hidden': signatureModal.open
    }"
  >
    <div class="pt-12 pb-20 lg:max-w-4xl lg:pb-0 mx-auto text-center">
      <div class="px-4 lg:px-6 pb-16 lg:pb-0">
        <div>
          <img
            v-if="isEarthquake"
            src="@/assets/jumpstart.png"
            class="mb-6 max-w-[300px]"
            alt="Jumpstart Logo"
          />
          <img
            v-else
            src="@/assets/pngs/Neptune_Horizon_JetBlack.png"
            class="w-full max-w-[24rem] mb-4 mx-auto"
            alt="Neptune Logo"
          />
          <div v-if="[6, 7, 8].includes(productId)" class="mb-4">
            <span
              class="text-sm font-bold bg-pink-100 text-pink-900 inline-flex rounded-full px-2 py-0.5"
            >
              Excess Flood
            </span>
          </div>

          <div :class="{ 'bg-black p-4 rounded bg-opacity-40': isEarthquake }">
            <h2 data-test="signPayHeader" class="text-jetblack-full">Policy Agreements</h2>
            <p class="text-lg mb-4 text-jetblack-full">
              Please sign the following to complete your application.
            </p>
            <div class="flex flex-col">
              <p class="text-jetblack-full mx-auto" data-test="signAsNotification">
                <span class="opacity-75">You are currently signing as the </span>
                <span v-if="party === 1" class="font-bold" data-test="signAsAgent">Agent</span>
                <span v-else class="font-bold" data-test="signAsInsured">Insured</span>
              </p>
              <button
                v-if="canSkipSigs"
                :class="{
                  'border-[#ff614c] text-[#ff614c] bg-white font-bold': isEarthquake,
                  'bg-deepsea-full hover:bg-deepsea-full/75 border-2  text-white': !isEarthquake
                }"
                class="border px-3 py-2 mt-4 sm:py-1.5 text-sm rounded w-1/4 mx-auto"
                @click="skipToPay"
              >
                Skip to payment
              </button>
            </div>
          </div>
        </div>
        <!-- Signatures -->
        <div ref="scroller" class="signature-container relative text-left">
          <div class="space-y-4 mt-8">
            <div
              v-for="(sig, i) in signatureModels"
              :key="'s' + i"
              class="bg-white text-gray-800 p-4 rounded"
              :class="{ active: activeIndex === i, '!mb-12': i === signatureModels.length - 1 }"
            >
              <DocumentSignatureSection
                ref="signature"
                :signature-image="sig.signedImage"
                :text="sig.docText"
                :data-test="`signPay-${i}`"
                class="signature"
                @sign="onSectionSign(sig, i)"
                @signed="focusNext($event.$el, i)"
              />
            </div>
          </div>
        </div>
        <!-- Popup -->
        <div
          v-if="showPopup"
          class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-auto px-2 py-4 z-50"
        >
          <slot name="popup" v-bind="$data">
            <el-card class="card my-auto text-left" header="Create a Signature">
              <PartySignatureForm
                ref="signatureForm"
                :name="activeSignature.signedByName || ''"
                :initials="activeSignature.ignedByInitials || ''"
                :can-change-use-name-text="true"
                @sign="applySignatureModelToSection"
                @update:model="updateActive"
              >
                <template #footer="{valid}">
                  <div class="space-x-2">
                    <button
                      class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-jetblack-full bg-lightair-medium hover:bg-lightair-medium/75 focus:outline-none space-x-1"
                      data-test="sigFormCancel"
                      @click="activeSignature = null"
                    >
                      Cancel
                    </button>
                    <button
                      :disabled="!valid || signatureTooLong"
                      class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-deepsea-full hover:bg-deepsea-full/75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepsea-medium space-x-1"
                      data-test="sigFormApply"
                      @click="applySignature"
                    >
                      Apply
                    </button>
                  </div>
                </template>
              </PartySignatureForm>
            </el-card>
          </slot>
        </div>
      </div>
      <!-- Continue -->
      <transition
        mode="out-in"
        enter-class="opacity-0"
        enter-active-class="transition ease-out duration-300"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-active-class="transition ease-in duration-300"
        leave-to-class="opacity-0"
      >
        <div
          v-if="signComplete"
          class="fixed lg:sticky  bottom-[20px] inset-x-0 pb-4 pt-7 flex items-center justify-end px-4 lg:px-6"
        >
          <button
            :disabled="loading"
            :class="{
              'bg-[#FF614C] text-white hover:bg-[#F23E26]': isEarthquake,
              'bg-deepsea-full text-white hover:bg-deepsea-full/75': !isEarthquake
            }"
            class="w-full lg:w-auto px-12 py-4 rounded-lg text-lg transition duration-200"
            data-test="docSignSubmit"
            @click="submitAndContinue"
          >
            Continue
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import DocumentSignatureSection from "@/components/policy-wizard/steps/sign/DocumentSignatureSection.vue";
import PartySignatureForm from "@/components/policy-wizard/steps/sign/PartySignatureForm.vue";
import cloneDeep from "lodash/cloneDeep";
import { findScrollableParent } from "@/components/policy-wizard/utils/dom";
import { TweenMax } from "gsap";
export default {
  components: {
    DocumentSignatureSection,
    PartySignatureForm
  },
  props: {
    /** @type {Vue.PropOptions<number>} */
    party: {
      type: Number,
      default: 2
    },

    /** @type {() => { data: neptune.documents.DocumentWithSignature[]}} */
    documents: {
      type: Array
    },

    loading: {
      type: Boolean
    },

    /** @type {() => neptune.IPolicyData} */
    policyData: Object
  },
  data: () => ({
    activeSignature: null,
    activeIndex: -1,
    partyModels: {},
    signatureModal: {
      open: false
    }
  }),
  computed: {
    signatureTooLong() {
      return this.activeSignature?.name?.length > 50;
    },
    isEarthquake() {
      return (
        this.policyData.transactions[this.policyData.transactions.length - 1].productId === 4 ||
        this.policyData.transactions[this.policyData.transactions.length - 1].productId === 5
      );
    },
    canSkipSigs() {
      return this.$store?.state?.auth?.data?.Roles?.includes("internal");
    },
    isCommercial() {
      return [2, 3, 7, 8].includes(
        this.policyData.transactions[this.policyData.transactions.length - 1].productId
      );
    },
    productId() {
      return this.policyData.transactions[this.policyData.transactions.length - 1].productId;
    },
    /**
     * @type {Vue.ComputedOptions<neptune.documents.Signature[]>}
     * @returns {neptune.documents.Signature[]}
     */
    signatureModels() {
      /**
      //  * TODO: fix this
      //  */
      const docs = this.documents;
      // const lastDoc = Array.isArray(docs) ? docs[docs.length - 1] : null
      const filteredSignatures = [];
      const party = this.party;
      docs.forEach((item) => {
        const sigs = item?.signatures?.filter((s) => s.party === party);
        sigs.forEach((item) => {
          filteredSignatures.push(item);
        });
      });
      return filteredSignatures;
    },
    signComplete() {
      return this.signatureModels?.every((s) => s.signedImage);
    },
    showPopup() {
      return this.activeSignature !== null;
    },

    submitting: {
      get() {
        return this.loading;
      }
    }
  },
  watch: {
    showPopup() {
      this.$root.$emit("toggleSignatureModalScroll");
    }
  },
  mounted() {
    document.body.style.overflow = "hidden";
    // prevent background scroll on signature modal open
    this.$root.$on("toggleSignatureModalScroll", () => {
      this.signatureModal.open = !this.signatureModal.open;
    });
  },
  destroyed() {
    document.body.style.overflow = "initial";
  },
  methods: {
    skipToPay() {
      this.$emit("skipToPayment");
    },
    /**
     * @param {neptune.documents.Signature} data
     */
    onSectionSign(data, index) {
      const party = this.signatureModels[index].party;
      if (this.partyModels[party]) {
        this.applySignatureModelToSection(this.partyModels[party], index);
      } else {
        this.activeIndex = index;
        this.activeSignature = cloneDeep(data);
      }
    },

    /**
     * @param {neptune.documents.ISignaturePartyModel} model
     */
    updateActive(model) {
      this.activeSignature = model;
    },

    /**
     * @type {() => void}
     */
    applySignature() {
      const party = this.signatureModels[this.activeIndex].party;

      this.partyModels[party] = this.activeSignature;

      /** @type {neptune.documents.Signature} */
      const m = {
        signedAt: new Date().toJSON(),
        signedByName: this.activeSignature.name,
        signedByInitials: this.activeSignature.initials,
        signedImage: this.activeSignature.nameBitmap
      };

      Object.assign(this.signatureModels[this.activeIndex], m);

      this.activeSignature = null;
    },

    async submitAndContinue() {
      if (this.party === 1) {
        this.$store.commit("policy/completeAgentSignature");
      }
      this.$emit("signaturesSubmission", this.signatureModels);
    },

    /**
     * @param {neptune.documents.ISignaturePartyModel} data
     * @param {number} index
     */
    applySignatureModelToSection(data, index) {
      /** @type {neptune.documents.Signature} */
      const m = {
        signedAt: new Date().toJSON(),
        signedByName: data.name,
        signedByInitials: data.initials,
        signedImage: data.nameBitmap
      };

      Object.assign(this.signatureModels[index], m);
    },

    focusNext(el, index) {
      const obj = this.$refs.signature[index + 1];

      /** @type {HTMLDivElement} */
      const scroller = findScrollableParent(this.$el);
      const middleOffset = scroller.getBoundingClientRect().height / 2;

      if (obj) {
        /** @type {HTMLButtonElement} */
        const btn = obj.$el.querySelector("button");

        const btnY = btn.getBoundingClientRect().y + scroller.scrollTop;

        TweenMax.to(scroller, 0.25, {
          scrollTop: btnY - middleOffset
        });
      } else {
        this.$nextTick(() => {
          TweenMax.to(scroller, 0.25, {
            scrollTop: scroller.scrollHeight - scroller.clientHeight
          });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(#000, 0.5);
  z-index: 1000;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.title {
  font-size: 1.3rem;
  text-align: center;
  margin-top: 3rem;

  p {
    margin: 1rem 0;
  }
}

.continue-button {
  margin: 3rem auto;
  align-self: center;
}

.card {
  width: 100%;
  max-width: 800px;
  align-self: center;
}

.signature {
  &-container {
    max-width: 1200px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    margin: 0 auto;
  }
}
.buttonOverride {
  color: rgb(255, 97, 76);
  background: white;
  border: 1px solid rgb(255, 97, 76);
  text-shadow: none;
  &:hover,
  &:active,
  &:focus {
    filter: tint(75%);
    color: white;
  }
}
</style>
